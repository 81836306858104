import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { InputStyles } from './input.styles';

const Input = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_8z0of99', 'template_3vumyhr', form.current, '37JUH0HgnhSRek2m9')
      .then((result) => {
          if(result.text){
            window.location.reload()
          }
      }, (error) => {
          console.log(error.text);
      });
  };

  // window.location.reload();

  return (
    <InputStyles>
    <form ref={form} onSubmit={sendEmail}>
      <div className=''>
        <label>Name</label>
        <br />
        <input className='fullname' type="text" name="user_name" />
      </div>
      <br />
      <div className=''>
        <label>Email</label>
        <br />
        <input className='fullname' type="email" name="user_email" />
      </div>
      <br />
      <div className=''>
        <label>Message</label>
        <br />
        <textarea className='messageinput' name="message" />
      </div>
      <br />
      <input className='button' type="submit" value="Send" />
    </form>
    </InputStyles>
  );
};

export default Input


