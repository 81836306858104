import { NextLevelStyles } from "./nextlevel.styles"
import { useNavigate } from 'react-router-dom';

const NextLevel = () => {
    const history = useNavigate(); 
    const contact = () => {
        history('./contact')
    }
    return (
        <NextLevelStyles>
            <div className="container">
                <div className='title-wrapper'>
                <h1 className="vision-title">Ready to take your business to the next level?</h1>
                <div className='button-container'>
                   <button onClick={contact}>Contact Us</button> 
                </div>
            </div>
            </div>
        </NextLevelStyles>
    )
}

export default NextLevel