import { WelcomeStyles } from './welcome.styles';

const Welcome = () => {
    return (
        <WelcomeStyles>
            <div className='container'>
                <h1 className='title'>WELCOME TO MICUSTA</h1>
                <p className='paragraph'>
                   our mission is to adopt and deploy the right tools and human skills to solve challenges efficiently and effectively. We position ourselves to enable our clients advance their economic and social performance objectives in win-win ways.
                </p>
            </div>
        </WelcomeStyles>
    )
}

export default Welcome;