import { ContactStyles } from './contact.styles';
import Input from '../input/input.component';

const Contact = () => {
    return (
        <ContactStyles>
        <div className="container">
            <br />
           <div className='contact-title'>
               <h1>Thank you for Visiting Micusta Concerns Limited</h1>
           </div>
           <br />
           <div className='contact-mail'>
               <p>Physical Address: 12B Ben Okagbue Mba Street, Lekki Phase1, Lagos</p>
               <br />
               <p>Email: micustaconcerns@gmail.com</p>
               <br />
               <p>Phone: +2348026990691</p>
           </div>
           <br />
           <div className='contact-mail'>
               <p>Write us a message here if you have any problem</p>
               <br />
               <Input />
           </div>
        </div>
        </ContactStyles>
        
    )
}

export default Contact;