import './App.css';
import React, { useState, useEffect } from "react";
import Header from './components/header/header.component';
import AboutUs from './components/about-us/about-us.component';
import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/home.page';
import Services from './components/services/services.component';
import Footer from './components/footer/footer.component';
import Contact from './components/contact/contact.component.';
import Executive from './components/executive/executive.component';
import MicustaLogo from "../src/assets/micustalogo.jpeg";

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false);
    }, 1000)
  }, [])
  
  return (
    <div>
    {
      loading ?  <div className='imgclass'><img src={MicustaLogo} alt=""/></div>
      :
    <div className="App">
      <div className='header'>
        <Header />
      </div>
     <Routes>
         <Route  path={'/'} exact={true}  element={<HomePage />} />
         <Route  path={'/vision'} exact={true}  element={<AboutUs />} />
         <Route  path={'/services'} exact={true}  element={<Services />} />
         <Route  path={'/contact'} exact={true}  element={<Contact />} />
         <Route  path={'/executive-management'} exact={true}  element={<Executive />} />
     </Routes>
     <br />
     <Footer />
    </div>
    }
    </div>
  );
}

export default App;
