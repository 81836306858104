import React, { useState } from 'react';
import { HeaderStyles } from './header.styles';
import { useNavigate } from 'react-router-dom';


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const history = useNavigate(); 

  const Home = () => {
      history('/')
  }

  return (
    <HeaderStyles>
    <div className="Navbar">
      <span onClick={Home} className="nav-logo">MICUSTA</span>
      <div className={`nav-items ${isOpen && "open"}`}>
        <a href="/">Home</a>
        <a href="/vision">Vision & Mission</a>
        <a href="/executive-management">Executive Management</a>
        <a href="/services">Services</a>
        <a href="/contact">Contact</a>
        {/* <a href="/contact">Request for services</a>  */}
      </div>
      <div
        className={`nav-toggle ${isOpen && "open"}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="bar"></div>
      </div>
    </div>
    </HeaderStyles>
  );
};

export default Navbar;



