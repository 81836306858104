import styled  from 'styled-components';

export const HeaderStyles = styled.div `

.Navbar {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #005670;
  }
  
  .Navbar > .nav-logo {
    font-weight: 700;
    font-size: 21px;
    margin: 15px;
    color: #ffffff;
    cursor: pointer;
  }

  .about {
    color: #ffffff;
    font-size: 16px;
    text-decoration: none;
    margin: 15px;
    position: relative;
    opacity: 0.9;
  }
  
  .Navbar > .nav-items > a {
    color: #ffffff;
    font-size: 16px;
    text-decoration: none;
    margin: 15px;
    position: relative;
    opacity: 0.9;
  }
  
  .Navbar > .nav-items > a:hover {
    opacity: 1;
  }
  
  .Navbar > .nav-items > a::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background: #ffffff;
    transition: all 0.45s;
  }
  
  .Navbar > .nav-items > a:hover::before {
    width: 100%;
  }
  
  .Navbar > .nav-toggle {
    display: none;
  }
  
  @media (max-width: 700px) {
    .Navbar > .nav-items {
      position: absolute;
      z-index: 1000000;
      top: 60px;
      display: flex;
      flex-direction: column;
      background-color: #005670;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateX(-100%);
      transition: all .45s;
    }
  
    .Navbar > .nav-items > a::before {
        background: transparent;
    }
  
    .Navbar > .nav-items.open {
        transform: translateX(0);
    }
  
    .Navbar > .nav-toggle {
      display: flex;
      width: 50px;
      height: 50px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
    .nav-toggle > .bar {
      position: relative;
      width: 32px;
      height: 2px;
      background: #ffffff;
      transition: all 0.45s ease-in-out;
    }
  
    .nav-toggle > .bar::before,
    .nav-toggle > .bar::after {
      content: "";
      position: absolute;
      height: 2px;
      background: #ffffff;
      border-radius: 2px;
      transition: all 0.45s ease-in-out;
    }
  
    .nav-toggle > .bar::before {
      width: 25px;
      transform: translateY(-8px);
      right: 0;
    }
  
    .nav-toggle > .bar::after {
      width: 32px;
      transform: translateY(8px);
    }
  
    .nav-toggle.open > .bar {
        transform: translateX(-40px);
        background: transparent;
    }
  
    .nav-toggle.open > .bar::before {
        width: 32px;
        transform: rotate(45deg) translate(26px, -26px);
    }
  
    .nav-toggle.open > .bar::after {
      transform: rotate(-45deg) translate(26px, 26px);
    }
  
  
  }
  
  `;



// import styled  from 'styled-components';

// export const HeaderStyles = styled.div `

// .navbar {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 75px;
//     background-color: #005670;
//     color: white;
//     z-index: 100;
// }

// // .logo {
// //     background-color: red;
// // }

// .nav-links {
//     display: flex;
//     justify-content: flex-end;
//     align-items: center;
//     height: 100%;
//     line-style: none;
//     width: 90%; 
//     align-content: center;
// }

// .about-us,
// .services,
// .insight,
// .contact,
// .request {
//     text-decoration: none;
//     color: white;
//     font-size: 20px;
//     padding: 10px 20px;
//     margin: 0 10px;
//     cursor: pointer;
// }

// .nav-text {
//     font-size: 14px;
//     font-weight: 500;
// }

// // .request {
// //      background-color: #02759A;
// // }

// .mobile-menu-icon {
//     display: none;
// }

// @media screen and (max-width: 780px) {
//     // z-index: 1000000000;
//     .logo {
//         display: flex;
//         position: absolute;
//         top: 15px;
//         left: 35px;
//     }

//     .nav-links {
//         display: none;
//     }

//     .nav-links-mobile {
//         position: absolute;
//         display: block;
//         list-style: none;
//         background-color: #005670;
//         left: 0;
//         top: 55px;
//         z-index: 1000000000;
//         transition: all 0.5s ease-out;
//         width: 100%;
//     }

// .about-us,
// .services,
// .insight,
// .contact,
// .request {
//    color: white;
//    text-align: center;
//    padding: 32px;
//    width: 100%;
//    transition: all 0.5s ease;
// }

// .mobile-menu-icon {
//     display: block;
//     position: absolute;
//     font-size: 36px;
//     color: white;
//     background-color: #005670;
//     border: none;
//     outline: none;
//     top: 15px;
//     right: 25px;
// }


// }

// `;