import styled  from 'styled-components';

export const AboutUsStyles = styled.div `
    width: 100%;

    .container {
        width: 98%;
        margin: 0 auto;
    }

    .title {
        text-align: center;
        font-size: 30px;
        font-weight: 600;
    }

    .who-text {
        font-size: 25px;
        font-weight: 600
    }

    .about-text {
        width: 95%;
        text-align: justify;
    }

    .image {
        padding-left: 10px;
    }

    ul {
        width: 90%;
        font-weight: 500;
        margin: 0 auto;
    }

    .executive-text,
    .mission-text,
    .vision-text,
    .core-text {
        width: 90%;
        text-align: justify;
        font-weight: 500;
        margin: 0 auto;
    }

    .executive-title,
    .mission-title,
    .vision-title,
    .core-title {
        width: 90%;
        margin: 0 auto;
        font-weight: 600;
        font-size: 25px;
        padding-bottom: 5px;
    }

    img {
        width: 95%;
    }

    @media screen and (max-width: 780px) {
        .who-we-are {
            display: flex;
            flex-direction: column;
        }

        .who-text {
            width: 90%;
            margin: 0 auto;
        }

        .about-text {
            width: 90%;
            margin: 0 auto;
            padding-top: 5px;
        }

        img {
            width: 98%;
        }
    }

`;