import { ServiceStyles } from './services.styles';
import CardComponent from '../card/card.component';


const Services = () => {
    return (
        <ServiceStyles>
            <div className='container'>
                <div className='title'>
                    <h1 className='title-text'>CORE SERVICES</h1>
                </div>
                <br />
                <br />
                <CardComponent />
            </div>
            <br />
            <br />
            <br />
        </ServiceStyles>
    )
}

export default Services;