import styled  from 'styled-components';

export const NextLevelStyles = styled.div `
   width: 100%;
   height: 150px;
   background-color: white;

   .container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      line-style: none;
      width: 100%; 
      align-content: center;
   }

   .button-container {
     display: flex;
     justify-content: center;
   }

   button {
    /* min-width: 25px; */
    width: 30%;
    height: 38px;
    letter-spacing: 0.5px; 
    line-height: 28px; 
    /* padding: 0 18px 0 18px; */
    font-size: 15px;
    background-color: black;
    color: white;
    font-family: Times New Roman;
    line-height: 22.5px;
    outline: none;
    border-radius: 15px;
    cursor: pointe

   }

   .vision-title {
     text-align: center;
     color: black;
     font-size: 30px;
     font-weight: 500;
   }

   .vision-paragraph {
     color: black;
     font-size: 15px;
   }

   @media screen and (max-width: 780px) {
    height: 100%;
    
    .vision-paragraph {
      width: 90%;
      margin: 0 auto;
      text-align: center;
      padding-bottom: 15px;
    }
}

`;