import styled  from 'styled-components';

export const HeroStyles = styled.div `
   width: 100%;
   position: relative;

   .title-container {
       width: 100%;
       position: absolute;
       top: 40%;
       z-index: 2;
   }

   .title {
       color: white;
       width: 50%;
       font-weight: 600;
       font-size: 3.5em;
   }

   .paragraph-text {
       font-size: 1.5em;
       width: 50%;
       margin-left: 8.8%;
       color: white;
       font-weight: 500;
       text-align: justify;
   }

   @media screen and (max-width: 780px) {

    .title {
       display: none;
    }

    .paragraph-text {
        display: none;
    }

    // .title-container{
    //     top: 10%;
    // }
   }
  

`;