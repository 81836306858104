import { ExecutiveStyles } from './executive.styles';
import Picture from '../../assets/pic.jpeg';

const Executive = () => {
    return (
        <ExecutiveStyles>
        <div className='executive'>
            <h1 className='executive-title'>EXECUTIVE MANAGEMENT</h1>
             <br />
             <h3 className='executive-title'>Owen Altraide <span>-</span> Chairman/Chief Executive Officer</h3>
             <br />
             <div className='image'>
                <img src={Picture} alt=""/>
            </div>
            <br />
            <p className='executive-text'>
            Owen Altraide is a Harvard trained Public Policy expert with a Masters in Public Administration with
            specialization in Financial Institutions and Macroeconomics. He was an Edward Mason Fellow of
            Public Policy and Management at Harvard University. He qualified as a Chartered Accountant with
            Arthur Andersen &amp; Co and holds a Business bachelor’s and an MBA, both from the University of
            Nigeria.
            </p>
        
            <p className='executive-text'>
              Owen has deep and broad Accounting, Auditing and Business Consulting experience gained from
              Arthur Andersen &amp; Co, and cutting-edge Financial Services Industry knowledge gained from working
              in the banking sector of the financial services industry for over fourteen years. He left banking as a
              Deputy General Manager mid 2005. He was admitted into the International Who’s Who of
              Professionals in 2005.
            </p>

            <p>
               Owen, at various times, had full responsibility for core retail and corporate banking functions that included, in the retail sphere,
               Area Manager of branches in Lagos/Western Nigeria, and, in the corporate banking sphere, Project
               Finance and Advisory Services; Corporate Finance; Energy and Structured Finance, and managing
               relationships with Multilateral Financial Institutions and Export Credit Agencies.
            </p>

            <p>
              He was selected to represent Nigeria during the Fall 2000 Best and Brightest African Bankers training
              programme funded by USAID during which time he had training stints with Chase Bank, New York;
              Mellon Bank, Philadelphia, and Multilateral Financial Institutions in Washington DC
            </p>

            <p>
               Owen also has over fourteen years of Oil and Gas industry exposure with Chevron where he was
               involved in Commercial and Business Development Advocacy, and, Drilling / Completions and
               Logistics Contracts Management, Compliance Management and Business Services Management for
               the Supply Chain Management Division.
            </p>

            <p>
            Owen is a fellow of the Institute of Chartered Accountants of Nigeria; fellow of the Chartered
            Institute of Taxation of Nigeria; holds a Professional Certificate in Supply Chain Management from
            Arizona State University, and is also a certified member of World Commerce and Contracting
            (formally International Association of Contract and Commercial Management - IACCM)
            </p>

            <p>
               Owen loves mentoring, listening to music and travelling.
            </p>
            
        </div>
        </ExecutiveStyles>
    )
}
export default Executive