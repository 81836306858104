import styled  from 'styled-components';

export const CardStyles = styled.div `
   width: 100%;

   .container {
       display: grid;
       grid-template-columns: auto auto auto;
       justify-content: center;
       grid-row-gap: 30px;
       grid-column-gap: 20px;
   }

   @media screen and (max-width: 780px) {
       .container {
           display: grid;
           grid-template-columns: auto;
       }
   }
  
`;