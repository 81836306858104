import { AboutUsStyles } from './about-us.styles';
// import ImageTwo from '../../assets/about.jpg';

const AboutUs = () => {
    return (
        <AboutUsStyles>
        <div className="container">
        <div className='about-us-title'>
                <h1 className="title">VISION, MISSION AND CORE VALUES</h1>
        </div>
        <br />
        <div className='vission'>
            <div className='v-text'>
                <h1 className='vision-title'>VISION</h1>
                <p className='vision-text'>
                  To be the Company of reference that adopts global best practices and leading technologies to
                  deliver goals in ethical manners
                </p>
            </div>  
        </div>
        <br />
        <br/>
        <br />
        <div className='vision'>
            <div className='v-text'>
                <h1 className='vision-title'>MISSION</h1>
                <p className='vision-text'>
                  To adopt and deploy the right tools and human skills to solve challenges efficiently and effectively.
                  We position ourselves to enable our clients advance their economic and social performance
                  objectives in win-win ways.
                </p>
            </div>  
        </div>
        <br />
        <br/>
        <div>
            <h1 className='core-title'>OUR CORE VALUES</h1>
            <p className='core-text'>Our way of doing business is founded on the following principles:</p>
            <ul>
                <br />
                <li>
                  Value Creation <span>-</span> we shall engage in mutually reinforcing relationships with our clients
                  that lead to enhanced benefits through optimization of costs and improvement of
                  revenues
                </li>
                <br />
                <li>
                    Integrity and Ethical choice <span>-</span> we shall be intentionally consistent when it concerns honesty, equity, truthfulness, accuracy and adherence to rules and regulations.
                </li>
                <br />
                <li>
                   Environment <span>-</span> we shall conduct our business with the believe that the environment
                   has to be protected and made sustainable for the future. We will be involved in
                   activities that both minimize carbon discharge to the environment and enhance the
                   quality of the environment
                </li>
                <br />
                <li>
                    Social Impact <span>-</span> we shall be socially responsive in the environments we do business by
                    consciously responding to the reasonable developmental needs of the stakeholders
                    around us
                </li>
                <br />
                <li>
                    Personal Growth and Development <span>-</span> we shall demonstrate commitment to the
                    advancement of the job knowledge and skills of our people, and the people of our
                    clients and partners
                </li>
                <br />
                <li>
                   Excellence <span>-</span> we shall have passion for excellence in people, processes and technology
                </li>
                <br />
                <li>
                   Inclusion <span>-</span> Our work environment shall be deliberately diverse, intentionally inclusive
                   and respectful
                </li>
            </ul>
        </div>
        <br />
        <br/>
        </div>
        </AboutUsStyles>
        
    )
}

export default AboutUs;