import styled  from 'styled-components';

export const FooterStyles = styled.div `
   width: 100%;
   background-color: black;
   position: relative;
   bottom:0;
   
   .container {
       display: flex;
       padding-top: 20px;
       padding-bottom: 20px;
       justify-content: space-around;
   }

   .services {
      text-decoration: none;
      color: #6C6C6C;
   }

   .facebook{
        color: #485893;
        cursor: pointer;
   }

   .twitter {
       color: #76A9EA;
       cursor: pointer;
   }

   .linkedin {
       color: #126294;
       cursor: pointer;
   }

   .instagram {
       color: #FE0302;
       cursor: pointer;
   }

   .icons {
       color: blue;
   }

   h1 {
       font-size: 20px;

   }

   p {
     font-size: 13px;
     color: #6C6C6C;
   }

   h1{
       color: white;
   }

   @media screen and (max-width: 780px) {
    .container {
        display: flex;
        flex-direction: column;
    }

    h1 {
        font-weight: 500;
    }

}
`;