import styled  from 'styled-components';

export const WelcomeStyles = styled.div `
   width: 100%;
   height: 250px;
   background-color: #005670;
   align-content: center;

   .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    line-style: none;
    width: 100%; 
    align-content: center;
   }

   .title {
       color: white;
       font-size: 35px;
       font-weight: 600;
       text-align: center;
       align-content: center;
   }

   .paragraph {
    color: white;
    width: 70%;
    text-align: center;
    align-content: center;
}

@media screen and (max-width: 780px) {
    height: 100%;

    .paragraph {
        width: 90%;
        margin: 0 auto;
        text-align: justify;
        padding-bottom: 20px;
    }

}


`;