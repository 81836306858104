import React from 'react';
import { HeroStyles } from './hero.styles';
import ImageOne from '../../assets/conference.jpg';
import ImageTwo from '../../assets/officetwo.jpg';
import ImageThree from '../../assets/doc.jpg';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


const Hero = () => {
        return (
            <HeroStyles>
            <Carousel 
            autoPlay={true}
            infiniteLoop={true} 
            interval={3000} 
            showThumbs={false}
            showIndicators={false}
            dynamicHeight={true}
            showStatus={false}
            stopOnHover={false}
            >
                <div>
                    <img src={ImageOne} alt=""/>
                </div>
                <div>
                    <img src={ImageTwo} alt=""/>
                </div>
                <div>
                    <img src={ImageThree} alt=""/>
                    {/* <div className='title-container'>
                        <h1 className='title'>VALUE CREATION</h1>
                        <p className='paragraph-text'>
                            we engage in mutually reinforcing relationships with our clients that lead to enhanced benefits through optimization of costs and improvement of enhanced revenues
                        </p>
                    </div> */}
                </div>
            </Carousel>
            </HeroStyles>
        );
    }
export default Hero;
