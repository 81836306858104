import { FooterStyles } from './footer.styles';
import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link } from 'react-router-dom';

const Footer = () => {

    const linkedinRoute = () => {
        window.location.href = 'https://www.linkedin.com/company/micusta-concerns-limited/?viewAsMember=true'
    }

    const instagramRoute = () => {
        window.location.href = "https://www.instagram.com/micustaconcernsltd/"
    }

    const facebookRoute = () => {
        window.location.href = "https://www.facebook.com/search/top?q=micusta/"
    }


    return (
        <FooterStyles>
            <div className='container'>
                <div className='links'>
                    <h1>Quick Links</h1>
                    <div className='services'>
                        <Link className='services' to="/services">Our Services</Link>
                    </div>
                    <div className='services'>
                         <Link className='services' to="/vision">Vision, Mission And Core Values</Link>
                    </div>
                    <div className='services'>
                         <Link className='services' to="/executive-management">Executive Management</Link>
                    </div>
                    <div className='services'>
                        <Link className='services' to="/contact">Contact Us</Link> 
                    </div>
                </div>

                <div className='contact'>
                    <h1>We are live on</h1>
                    <div onClick={facebookRoute}>
                       <FacebookIcon className="facebook"/> 
                    </div>
                    <br />
                    <div onClick={instagramRoute}>
                       <InstagramIcon className="instagram"/> 
                    </div>
                    <br />
                    <div onClick={linkedinRoute}>
                        <LinkedInIcon className="linkedin"/> 
                    </div>
                </div>

            </div>
        </FooterStyles>
    )
}

export default Footer;