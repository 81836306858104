import styled  from 'styled-components';

export const ExecutiveStyles = styled.div `
  width: 100%;

  .executive {
    width: 97%;
    margin: 0 auto;
  }

  .image{
    width:20%;
  }

  img {
    width: 100%;
  }

  @media screen and (max-width: 780px) {

    .image {
      width: 90%;
      margin: 0 auto;
    }

     img {
      width: 100%;
     }
}
`;


