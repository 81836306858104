import styled  from 'styled-components';

export const InputStyles = styled.div `
   width: 100%;

   .fullname {
      width: 50%;
    }

    .messageinput {
      width: 50%;
      height: 80px;
    }


    .button {
        /* min-width: 25px; */
        width: 50%;
        letter-spacing: 0.5px; 
        line-height: 28px; 
        /* padding: 0 18px 0 18px; */
        font-size: 15px;
        background-color: black;
        color: white;
        font-family: Times New Roman;
        line-height: 22.5px;
        border-radius: 5px;
        outline: none;
        cursor: pointe
       }

   @media screen and (max-width: 780px) {
         .email,
         .fullname,
         .message {
            width: 98%;
         }
     }

`;