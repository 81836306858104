import Hero from '../../src/components/hero/hero.component';
import Welcome from '../../src/components/welcome/welcome.component';
import Services from '../../src/components/services/services.component';
import NextLevel from '../components/nextlevel/nextlevel.component';

const HomePage = () => {
    return (
        <div>
            <Hero />
            <Welcome />
            <br />
            <br />
            <Services />
            <br />
            <NextLevel />
            {/* <br />
            <br />
            <CoreValues /> */}
        </div>
    )
}

export default HomePage;