import styled  from 'styled-components';

export const ServiceStyles = styled.div `
   width: 100%;

   .title-text {
      text-align: center;
      color: black;
      font-size: 30px;
   }
`;